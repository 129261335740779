import React from 'react';
import PropTypes from 'prop-types';

import { Layout, SEO } from 'components';

import content from './privacy.yml';

const PrivacyPage = ({ pageContext: { locale }, location }) => (
  <Layout locale={locale}>
    <SEO
      title={content.title}
      description={content.description}
      lang={locale}
      keywords={content.keywords}
      location={location}
      image={content.socialImage}
    />

    <div className='text-wrapper mt-90 mb-90'>
      <h1 className='section-title mb-50'>Straight Teeth Direct&trade;<br/>Privacy</h1>

      <h3 className='mt-40 mb-20'>1. Introduction</h3>
      <p><b>1.1</b>  This is our privacy policy. It tells you how we collect and process data received from you on our site or through our mobile app in connection with our online platform connecting patients to registered dentists for the provision of dental aligners (the “<b>Service</b>”).</p>
      <p><b>1.2</b>  If you have any comments on this privacy policy, please email them to hello@straightteethdirect.com.</p>

      <h3 className='mt-40 mb-20'>2. Who We Are</h3>
      <p><b>2.1</b>  We are registered with the Data Protection Commissioner as a data controller under the Data Protection Acts 1998 and 2003 (the “<b>Acts</b>”).</p>
      <p><b>2.2</b>  Our registered address is Unit 3d North Point House, North Point Business Park, New Mallow Road, Cork.</p>
      <p><b>2.3</b>  Our company name is Straight Teeth Direct Europe Ltd.</p>
      <p><b>2.4</b>  Our registered address is Unit 3d North Point House, North Point Business Park, New Mallow Road, Cork.</p>
      <p><b>2.5</b>  Our nominated contact address is hello@straightteethdirect.com.</p>

      <h3 className='mt-40 mb-20'>3. What we may collect</h3>
      <p><b>3.1</b>  We may collect and process the following data about you that may, in certain circumstances, constitute personal data and/or sensitive personal data:</p>
      <p className='ml-20'><b>3.1.1</b>  password;</p>
      <p className='ml-20'><b>3.1.2</b>  name;</p>
      <p className='ml-20'><b>3.1.3</b>  e-mail address;</p>
      <p className='ml-20'><b>3.1.4</b>  phone number;</p>
      <p className='ml-20'><b>3.1.5</b>  postal address;</p>
      <p className='ml-20'><b>3.1.6</b>  date of birth;</p>
      <p className='ml-20'><b>3.1.7</b>  gender;</p>
      <p className='ml-20'><b>3.1.8</b>  bank account or credit card details;</p>
      <p className='ml-20'><b>3.1.9</b>  information you put into apps, forms or surveys on our site or app at any time (including sensitive personal health data);</p>
      <p className='ml-20'><b>3.1.10</b>  a record of any correspondence between us;</p>
      <p className='ml-20'><b>3.1.11</b>  details of transactions you carry out through our site or app;</p>
      <p className='ml-20'><b>3.1.12</b>  details of your visits to our site and the resources you use; and</p>
      <p className='ml-20'><b>3.1.13</b>  information about your device, computer (e.g. your IP address, browser, operating system, etc.) for system administration and to report aggregate information to our advertisers</p>

      <h3 className='mt-40 mb-20'>4. Cookies</h3>
      <p><b>4.1</b>  We collect the Internet Protocol (IP) address of the device you use to access the Service, information about the operating system of your device and information about whether or not you are logged into a specific iOS device, as well as the name of your internet service provider.</p>
      <p><b>4.2</b>  We may place a “cookie” on the hard drive of the device that you use to access the Service. Cookies are text files that are saved on the hard drive of your device by means of your browser, enabling us to recognise your browser for purposes such as saving your preferences and directing relevant content to you. The cookies that we use may be persistent cookies (which are cookies that will continue to exist even after the given browser session is closed) or session cookies (which are cookies that only last for a given browser session). This helps us to provide you with a good experience when you use the Service and also allows us to improve our Service. Most of the currently available browsers give you the option of managing cookies by, for example, disabling them entirely, accepting them individually, and deleting saved cookies from your hard drive. We would like to remind you that if you completely disable cookies on your browser, you might not be able to use some features of the Service. Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control and in respect of which we will have no responsibility. By using the Service (with your browser settings set to accept cookies) you are consenting to the use of cookies as described in this Privacy Policy.</p>
      <p><b>4.3</b>  Google Analytics may be an element of the Service. By using cookies, Google Analytics collects and stores data such as time of visit, pages visited, time spent on each page of the website, the Internet Protocol address, and the type of operating system used in the devices used to access the Service. By using a browser plug-in provided by Google, you can opt out of Google Analytics.</p>
      <p><b>4.4</b>  Moreover, details of transactions you carry out through the Service, details of your visits to the Service including, but not limited to, traffic data, location data, weblogs and other communication data may also be collected.</p>

      <h3 className='mt-40 mb-20'>5. How we use what we collect</h3>
      <p><b>5.1</b>  We use information about you to:</p>
      <p className='ml-20'><b>5.1.1</b>  present site and app content effectively to you;</p>
      <p className='ml-20'><b>5.1.2</b>  to manage the Service;</p>
      <p className='ml-20'><b>5.1.3</b>  provide information, products and services that you request, or (with your consent) which we think may interest you;</p>
      <p className='ml-20'><b>5.1.4</b>  carry out our contracts with you;</p>
      <p className='ml-20'><b>5.1.5</b>  Tell you our charges</p>
      <p className='ml-20'><b>5.1.6</b>  to share with authorised clinicians and dentists and laboratories to enable your service to be fulfilled;</p>
      <p className='ml-20'><b>5.1.7</b>  for market research;</p>
      <p className='ml-20'><b>5.1.8</b>  to audit and analyse the Service;</p>
      <p className='ml-20'><b>5.1.9</b>  to prevent and investigate fraud and other misuses;</p>
      <p className='ml-20'><b>5.1.10</b>  to protect our rights and/or our property; and</p>
      <p className='ml-20'><b>5.1.11</b>  tell you about other goods and services that might interest you.</p>
      <p><b>5.2</b>  If you are already our customer, we will only contact you electronically about things similar to what was previously sold to you.</p>
      <p><b>5.3</b>  If you are a new customer, you will only be contacted if you agree to it.</p>
      <p><b>5.4</b>  If you don’t want to be contacted for marketing purposes, please tick the relevant box that you will find on screen.</p>
      <p><b>5.5</b>  Please note: We don’t identify individuals to our advertisers, but we do give them aggregate information to help them reach their target audience, and we may use information we have collected to display advertisements to that audience.</p>

      <h3 className='mt-40 mb-20'>6. Where we store your data</h3>
      <p><b>6.1</b>  We may transfer your collected data to storage outside the European Economic Area (EEA). It may be processed outside the EEA to fulfil your order and deal with payment.</p>
      <p><b>6.2</b>  By giving us your personal data, you agree to this arrangement. We will do what we reasonably can to keep your data secure.</p>
      <p><b>6.3</b>  Payment will be encrypted. If we give you a password, you must keep it confidential. Please don’t share it. Although we try to provide protection, we cannot guarantee complete security for your data, and you take the risk that any sending of that data turns out to be not secure despite our efforts.</p>

      <h3 className='mt-40 mb-20'>7. Disclosing your information</h3>
      <p><b>7.1</b> We are allowed to disclose your information in the following cases:</p>

      <p className='ml-20'><b>7.1.2</b>  in connection with the provision of the Service (including the disclosure of your data to relevant dental professionals and manufacturers and online payment service providers);</p>
      <p className='ml-20'><b>7.1.3</b>  if we want to sell our business, or our company, we can disclose it to the potential buyer;</p>
      <p className='ml-20'><b>7.1.4</b>  we can disclose it to other businesses in our group;</p>
      <p className='ml-20'><b>7.1.5</b>  we can disclose it if we have a legal obligation to do so, or in order to protect other people’s property, safety or rights</p>
      <p><b>7.2</b>  We can exchange information with others to protect against fraud or credit risks.</p>

      <h3 className='mt-40 mb-20'>8. Your rights</h3>
      <p><b>8.1</b>  You can ask us not to use your data for marketing. You can do this by ticking the relevant boxes on our forms, or by contacting us at any time at hello@straightteethdirect.com.</p>
      <p><b>8.2</b>  The Acts give you the right to see information we hold about you. We can charge you a fee (currently €6.35) for this service.</p>

      <h3 className='mt-40 mb-20'>9. Links to other sites</h3>
      <p><b>9.1</b>  Please note that our terms and conditions and this privacy policy will not apply to other websites that you get to via a link from our site.  You acknowledge that where you access the website content of, correspond with, and purchase products and services from third parties via third-party websites you do so solely at your own risk.  We make no representation or commitment and shall have no liability or obligation whatsoever in relation to the content or use of, or correspondence with, any such third party website, or any transactions completed, and any contract entered into by you, with any such third party and the use by any such third-party of your personal data.  Please be aware that we are not responsible for the privacy practices of any third parties.</p>

      <h3 className='mt-40 mb-20'>10. Consent</h3>
      <p><b>10.1</b>  By using our site or app you accept the privacy practices described in this privacy policy and consent to the use of your personal data as set out above.</p>

      <h3 className='mt-40 mb-20'>11. Changes</h3>
      <p><b>11.1</b>  If we change our Privacy Policy, we will post the changes on this page. If we decide to, we may also email you.</p>

      <h3 className='mt-40 mb-20'>12. Governing Law</h3>
      <p><b>12.1</b>  The laws of Ireland will cover this Privacy Policy and any claim, cause of action or dispute arising out of or relating to this Privacy Policy will be brought solely in the courts of Ireland.</p>
    </div>
  </Layout>
);

PrivacyPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
};

export default PrivacyPage;
